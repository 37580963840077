import React, { Component } from 'react';
import { Resizable } from "re-resizable";
import { Button } from 'reactstrap';

export default class ResizableWithControls extends Component {
  constructor() {
    super();
    this.state = {
      height: null,
      increment: 55
    };
  }

  increaseSize = () => {
    const { defaultHeight } = this.props;
    this.setState(prevState => ({
      height: (prevState.height || defaultHeight) + prevState.increment
    }));
  };

  decreaseSize = () => {
    const { defaultHeight } = this.props;
    this.setState(prevState => ({
      height: Math.max((prevState.height || defaultHeight) - prevState.increment, 50)
    }));
  };

  render() {
    const { height } = this.state;
    const { defaultHeight, className, children, enable, wrapperClass } = this.props;
    return (
      <div className={wrapperClass}>
        <Resizable
          className={className}
          defaultSize={{ height: defaultHeight }}
          size={{ height: height || defaultHeight }}
          enable={ enable }
          sizeStyle={{height: 'min-height: 50px'}}
          onResizeStop={(e, direction, ref, d) => {
            this.setState({
              height: (height || defaultHeight) + d.height
            });
          }}
        >
          {children}
          <div className="marker-items-nav position-absolute bottom width-100percent bg-transparent border-t">
            <div className="d-flex justify-content-center align-items-center h-0">
              <i className="fa fa-chevron-up mr-3 scale-3-0 cursor-pointer text-black" onClick={this.decreaseSize}></i>
              <i className="fa fa-chevron-down ml-3 scale-3-0 cursor-pointer text-black" onClick={this.increaseSize}></i>
            </div>
          </div>
        </Resizable>
      </div>
    );
  }
}
